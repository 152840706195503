// src/RedirectIfAuthenticated.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const RedirectIfAuthenticated = ({ component }) => {
  const { currentUser } = useAuth();

  // If the user is logged in, redirect them to /articles-list
  if (currentUser) {
    return <Navigate to="/" />;
  }

  // If not logged in, render the provided component (e.g., Login or Signup)
  return component;
};

export default RedirectIfAuthenticated;
