import React from 'react';
import Footer from './Footer';
import AppNavbar from './AppNavbar';
import { Container, Navbar, Image, Button, Nav } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GoMoon, GoSun } from 'react-icons/go';
import { DarkModeContext } from './DarkModeContext'; // Import the context

function Layout({ children }) {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    // Check for saved dark mode preference
    const savedDarkMode = localStorage.getItem('dark-mode') === 'true';
    setDarkMode(savedDarkMode);
    // Apply dark class on the body tag
    document.body.classList.toggle('dark', savedDarkMode);
  }, []);

  // Toggle dark mode state and apply to localStorage
  const toggleDarkMode = () => {
    setDarkMode(prevMode => {
      const newMode = !prevMode;
      document.body.classList.toggle('dark', newMode);
      localStorage.setItem('dark-mode', newMode);
      return newMode;
    });
  };

  return (
        <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>

    <div data-bs-theme={darkMode ? 'dark' : 'light'}>

          <Navbar className="bg-body-tertiary">
            <Container className="d-flex justify-content-between">
              <Navbar.Brand as={Link} to="/">
                <Image src="../logo.png" width="60" height="60" />
              </Navbar.Brand>

              <Nav>
                <Button
                  variant="link"
                  onClick={toggleDarkMode}
                  className="dark-mode-toggle"
                  aria-label="Toggle dark mode"
                  >
                  {darkMode ? <GoSun /> : <GoMoon />}
                </Button>
              </Nav>
            </Container>
          </Navbar>

          <AppNavbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />

          <Container className="mt-4 app-content">      
              <main>{children}</main>
          </Container>
          <Footer />    
      </div>
          </DarkModeContext.Provider>

  );
}

export default Layout;