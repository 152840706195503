import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useEffect } from 'react';

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <h1 className="text-center">من نحن</h1>
                    <p className="text-center mt-3">
                        مرحباً بكم في <strong className="site-title">Soumar Tech Park</strong>
                    </p>
                    <p>
                        في <strong className="site-title">Soumar Tech Park</strong>، نحن شغوفون بالألعاب وكل ما يتعلق بها. مهمتنا هي تقديم محتوى مميز للاعبين، أحدث الأخبار، المراجعات، والنصائح التي تعزز تجربة اللعب لديهم. سواء كنت لاعباً عادياً أو محترفاً، نحن نسعى لتغطية مجموعة واسعة من المواضيع التي تناسب جميع مستويات خبرة الألعاب.
                    </p>
                    <p>
                        تأسسنا في عام <strong>2021</strong>، وأصبح مدونتنا بسرعة مركزاً للاعبين لاستكشاف الاتجاهات الجديدة، واكتشاف استراتيجيات الألعاب، والتواصل مع عشاق الألعاب الآخرين. نحن نؤمن بإنشاء مجتمع يمكن للجميع فيه مشاركة حبهم للألعاب.
                    </p>
                    <p>
                        لا تتردد في تصفح مقالاتنا. نحن نقدر دعمكم ونتطلع إلى مشاركة رحلتنا في عالم الألعاب معكم!
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default About;
