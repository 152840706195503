import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Spinner, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { firestore } from './Authentication/firebase';
import { collection, getDocs, query, orderBy, where, limit, startAfter } from 'firebase/firestore';
import { formatDistanceToNow } from 'date-fns';
import { ar } from 'date-fns/locale';
import { FaRegClock } from 'react-icons/fa';

const HomePage = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [featuredPosts, setFeaturedPosts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const batchSize = 6; // Number of articles to load per batch

    useEffect(() => {
        fetchPosts();
        fetchFeaturedPosts();
    }, []);

    const fetchPosts = async (next = false) => {
        setLoading(true);
        try {
            const postsRef = collection(firestore, 'articles');
            let q = query(postsRef, orderBy('createdAt', 'desc'), limit(batchSize));

            if (next && lastVisible) {
                q = query(postsRef, orderBy('createdAt', 'desc'), startAfter(lastVisible), limit(batchSize));
            }

            const querySnapshot = await getDocs(q);

            const posts = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            setBlogPosts((prevPosts) => (next ? [...prevPosts, ...posts] : posts));
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setHasMore(querySnapshot.docs.length === batchSize);
        } catch (err) {
            setError('Failed to fetch posts from Firestore: ' + err.message);
            console.error('Error fetching posts:', err);
        } finally {
            setLoading(false);
        }
    };

    const fetchFeaturedPosts = async () => {
        try {
            const postsRef = collection(firestore, 'articles');
            const q = query(postsRef, where('featured', '==', true), limit(5));
            const querySnapshot = await getDocs(q);

            const posts = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            setFeaturedPosts(posts);
        } catch (err) {
            setError('Failed to fetch featured posts: ' + err.message);
            console.error('Error fetching featured posts:', err);
        }
    };

    const formatDate = (articleDate) => {
        return new Date(articleDate).toISOString().split('T')[0];
        //return formatDistanceToNow(new Date(date), { addSuffix: true, locale: ar });
    };

    if (loading && blogPosts.length === 0) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status" />
            </div>
        );
    }

    return (
        <div>
            {error && <p className="text-danger text-center">{error}</p>}

            {/* Carousel Section */}
            {featuredPosts.length > 0 && (
                <Row>
                    <Carousel>
                        {featuredPosts.map((post) => (
                            <Carousel.Item key={post.id}>
                                <img
                                    className="d-block w-100"
                                    src={post.imageUrl}
                                    alt={post.title}
                                />
                                <Carousel.Caption className="carousel-caption">
                                    <h3>
                                        <Link to={`/articles/${post.slug}`} className='carousel-title'>
                                            {post.title}
                                        </Link>
                                    </h3>
                                    <p className='carousel-intro'>{post.intro}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Row>
            )}

            {/* Blog Posts Section */}
            <Row className="mt-5">
                <Col></Col>
                <Col md="8">
                    <h3 className="font-weight-bold">آخر المقالات</h3>
                    {blogPosts.map((post) => (
                     <Col md={12} key={post.id} className="mb-3">
                        <div className="d-flex flex-column flex-md-row home-article-border-bottom">
                            {/* Image Section */}
                            <div className="p-2 text-center text-md-start">
                                <Image 
                                    src={post.imageUrl} 
                                    rounded 
                                    className="note-image" 
                                    alt={post.title} 
                                />
                            </div>

                            {/* Content Section */}
                            <div className="p-2">
                                {/* Category */}
                                <div className="category mb-1">
                                    {post.category === 'Reports' ? (
                                        <Link to="/reports">تقارير</Link>
                                    ) : post.category === 'Reviews' ? (
                                        <Link to="/reviews">مراجعات</Link>
                                    ) : post.category === 'News' ? (
                                        <Link to="/news">أخبار</Link>
                                    ) : (
                                        post.category
                                    )}
                                </div>

                                {/* Title */}
                                <div className="mb-1">
                                    <Link to={`/articles/${post.slug}`} className="post-title">
                                        {post.title}
                                    </Link>
                                </div>

                                {/* Date */}
                                <div className="mb-1 home-page-date">
                                    <FaRegClock /> {formatDate(post.createdAt)}
                                </div>
                            </div>
                        </div>
                    </Col>

                    ))}
                </Col>
                <Col></Col>
            </Row>

            {hasMore && (
                <div className="text-center my-4">
                    <Button onClick={() => fetchPosts(true)} disabled={loading} variant="danger">
                        {loading ? <Spinner animation="border" role="status" size="sm" /> : 'تحميل المزيد'}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default HomePage;
