import './App.scss';
import './LightMode.scss';
import './DarkMode.scss';
import HomePage from './HomePage';
import ArticlePage from './ArticlePage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Terms from './OtherPages/Terms';
import About from './OtherPages/About';
import Privacy from './OtherPages/Privacy';
import ContactUs from './OtherPages/ContactUs';
import AddArticle from './AddArticle';
import Login from './Authentication/Login';
import Signup from './Authentication/Signup';
import Layout from './Layout';
import ArticlesList from './ArticlesList';
import { AuthProvider } from "./Authentication/AuthProvider";
import ProtectedRoute from "./Authentication/ProtectedRoute";
import RedirectIfAuthenticated from './Authentication/RedirectIfAuthenticated';
import EditArticlePage from './EditArticlePage';
import Profile from './Profile';
import Settings from './Settings';
import NotFoundPage from './NotFoundPage'; // Import the NotFoundPage
import CategoryPage from './CategoryPage';
import ScrollToTopButton from './ScrollToTopButton';
import Messages from './Messages';
import CookieConsent from 'react-cookie-consent';
import { useEffect } from 'react';
// import Encyclopedia from './Encyclopedia';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function App() {
    const enableGoogleAds = () => {
    // Dynamically load the Google AdSense script only after consent
    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
  };

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "true") {
      enableGoogleAds();
    }
  }, []);

  return (
    <Router>
      <CookieConsent
        location="bottom"
        buttonText="قبول الكل"
        declineButtonText="رفض"
        enableDeclineButton
        onAccept={() => {
          localStorage.setItem("cookieConsent", "true");
          enableGoogleAds(); // Load ads if consent is given
        }}
        onDecline={() => {
          localStorage.setItem("cookieConsent", "false");
          console.log("Cookies declined. Non-essential scripts are disabled.");
        }}
        cookieName="publicCookieConsent"
        style={{
          background: "#2B373B",
          color: "#fff",
        }}
        buttonStyle={{
          color: "#fff",
          backgroundColor: "#28a745",
          fontSize: "14px",
        }}
        declineButtonStyle={{
          color: "#fff",
          backgroundColor: "#dc3545",
          fontSize: "14px",
        }}
      >
        نستخدم ملفات تعريف الارتباط (الكوكيز) لتحسين تجربتك وتقديم إعلانات مخصصة. من خلال النقر على "قبول الكل"، فإنك توافق على استخدامنا لملفات تعريف الارتباط. يرجى قراءة سياسة الخصوصية الخاصة بنا للحصول على مزيد من التفاصيل.
        <Link to="privacy-policy" style={{ color: "#61dafb" }}>
          سياسة الخصوصية
        </Link>{" "}
        
      </CookieConsent>
      <Helmet>
            <meta property="og:url" content={"https://www.soumar-techpark.com/"} />
            <meta name="description" content="موقع عربي يهتمّ بالتقنية، أكثر ما نهتمّ به هُو الجودة والبساطة" />
            {/* <meta name="theme-color" content="rgb(225, 48, 108)" /> */}
            <meta property="og:image" content="soumar-tech-park.jpg" />
            <meta property="og:title" content="Soumar Tech Park" />
            <meta property="og:image:width" content="500" />
            <meta property="og:image:height" content="500" />
            <meta name="og:type" content="website" />
          </Helmet>
      <AuthProvider>
        <Layout>
          <ScrollToTopButton />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/articles/:slug" element={<ArticlePage />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-of-service" element={<Terms />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/reports" element={<CategoryPage category="reports" />} />
            <Route path="/reviews" element={<CategoryPage category="reviews" />} />
            <Route path="/news" element={<CategoryPage category="news" />} />
            <Route path="/messages" element={<Messages />} />
            {/* <Route path="/encyclopedia" element={<Encyclopedia />} /> */}
            <Route path="/profile/:username"
              element={             
                  <Profile />
              }
            />

            <Route path="/add"
              element={             
                <ProtectedRoute>             
                  <AddArticle />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/edit/:slug"
              element={
                  <ProtectedRoute>
                      <EditArticlePage />
                  </ProtectedRoute>
              }
          />

            <Route path="/settings" 
              element={             
                <ProtectedRoute>             
                  <Settings />
                </ProtectedRoute>
              } 
            />

            <Route path="/login" 
              element={<RedirectIfAuthenticated component={<Login />} />} 
            />
            
            <Route path="/signup" 
              element={<RedirectIfAuthenticated component={<Signup />} />} 
            />

            {/* Protected Route for ArticlesList */}
            <Route path="/articles-list" 
              element={
                <ProtectedRoute>
                  <ArticlesList />
                </ProtectedRoute>
              }
            />

            {/* 404 Not Found Route */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </AuthProvider>
    </Router>
  );
}

export default App;
