import { useState, useEffect } from 'react';
import { auth } from "./Authentication/firebase";
import { updateEmail, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { useAuth } from "./Authentication/AuthProvider"; // Your custom auth context

const SettingsPage = () => {
  const { currentUser } = useAuth(); // Get current user from context
  const [email, setEmail] = useState(currentUser ? currentUser.email : "");
  const [newEmail, setNewEmail] = useState("");  // Store new email input
  const [currentPassword, setCurrentPassword] = useState("");  // Store current password for reauthentication
  const [newPassword, setNewPassword] = useState("");  // Store new password
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  // Handle Email Update with Reauthentication
  const handleUpdateEmail = () => {
    setError("");
    setSuccess("");

    if (newEmail && newEmail !== email) {
      // Reauthenticate the user first before updating the email
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, currentPassword);

      reauthenticateWithCredential(user, credential)
        .then(() => {
          // After successful reauthentication, update the email
          updateEmail(auth.currentUser, newEmail)
            .then(() => {
              setEmail(newEmail);  // Update the displayed email with the new one
              setSuccess("تم تحديث البريد الإلكتروني بنجاح!");
              setNewEmail("");  // Clear the new email input
              setCurrentPassword("");  // Clear the current password input
            })
            .catch((error) => {
              setError("خطأ في تحديث البريد الإلكتروني: " + error.message);
            });
        })
        .catch((error) => {
          setError("خطأ في إعادة المصادقة: " + error.message);
        });
    } else {
      setError("يرجى إدخال بريد إلكتروني جديد مختلف عن الحالي.");
    }
  };

  // Handle Password Update
  const handleUpdatePassword = async () => {
    setError("");
    setSuccess("");

    // Re-authenticate the user first
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    try {
      await reauthenticateWithCredential(user, credential);

      if (newPassword) {
        await user.updatePassword(newPassword);
        setSuccess("تم تحديث كلمة المرور بنجاح!");
        setNewPassword("");
        setCurrentPassword("");
      }
    } catch (error) {
      setError("خطأ في تحديث كلمة المرور: " + error.message);
    }
  };

  return (
    <Container className="mt-5">
      <h2>الإعدادات</h2>

      {/* Display error and success messages */}
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      <Form>
        {/* Display Current Email */}
        <Form.Group controlId="formEmail" className="mt-3">
          <Form.Label>البريد الإلكتروني الحالي</Form.Label>
          <Form.Control
            type="email"
            value={email}  // Show the current email
            disabled  // Make the field read-only
          />
        </Form.Group>

        {/* Input for New Email */}
        <Form.Group controlId="formNewEmail" className="mt-3">
          <Form.Label>البريد الإلكتروني الجديد</Form.Label>
          <Form.Control
            type="email"
            placeholder="أدخل البريد الإلكتروني الجديد"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}  // Handle input change
          />
        </Form.Group>

        {/* Input for Current Password (needed for reauthentication) */}
        <Form.Group controlId="formCurrentPassword" className="mt-3">
          <Form.Label>كلمة المرور الحالية</Form.Label>
          <Form.Control
            type="password"
            placeholder="أدخل كلمة المرور الحالية"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}  // Handle current password input
          />
        </Form.Group>

        <Button variant="primary" className="mt-3" onClick={handleUpdateEmail}>
          حفظ تغييرات البريد الإلكتروني
        </Button>
      </Form>

      <hr />

      <Form>
        {/* Current Password Input for Password Change */}
        <Form.Group controlId="formCurrentPassword">
          <Form.Label>كلمة المرور الحالية</Form.Label>
          <Form.Control
            type="password"
            placeholder="أدخل كلمة المرور الحالية"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}  // Handle current password input
          />
        </Form.Group>

        {/* New Password Input */}
        <Form.Group controlId="formNewPassword" className="mt-3">
          <Form.Label>كلمة المرور الجديدة</Form.Label>
          <Form.Control
            type="password"
            placeholder="أدخل كلمة المرور الجديدة"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}  // Handle new password input
          />
        </Form.Group>

        <Button variant="danger" className="mt-3" onClick={handleUpdatePassword}>
          تغيير كلمة المرور
        </Button>
      </Form>
    </Container>
  );
};

export default SettingsPage;
