import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { firestore } from './Authentication/firebase'; // Firebase configuration file
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';

const ArticlesList = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Track loading state

    // Fetch articles from Firestore
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'articles')); // Fetch articles collection
                const articles = [];
                querySnapshot.forEach((docSnap) => {
                    articles.push({ ...docSnap.data(), id: docSnap.id }); // Add doc id to each article
                });
                setBlogPosts(articles);
            } catch (err) {
                setError(err.message);
                console.error('Error fetching posts:', err);
            } finally {
                setLoading(false); // Set loading to false after fetching is done
            }
        };

        fetchPosts();
    }, []); // Empty dependency array means this effect runs once on mount

    // Handle delete operation
    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this article?');
        if (confirmDelete) {
            try {
                await deleteDoc(doc(firestore, 'articles', id)); // Delete document from Firestore
                // Remove the deleted post from the state
                setBlogPosts(blogPosts.filter(post => post.id !== id));
            } catch (err) {
                setError(err.message);
                console.error('Error deleting post:', err);
            }
        }
    };

    return (
        <Container>
            <h1 className="my-4">قائمة المقالات</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            {loading ? (
                // Display spinner in the center of the page when loading
                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>العنوان</th>
                            <th>تاريخ النشر</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {blogPosts.map(post => (
                            <tr key={post.id}>
                                {/* Title with ellipsis for long titles */}
                                <td className="text-truncate" style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {post.title}
                                </td>

                                {/* Display formatted date */}
                                <td>{post.createdAt}</td>

                                <td className="d-flex justify-content-start align-items-center">
                                    <Link to={`/articles/${post.slug}`} className="me-2">
                                        <Button size='sm' variant="light">
                                            <FaEye /> مشاهدة
                                        </Button>
                                    </Link>

                                    <Link to={`/edit/${post.slug}`} className="me-2">
                                        <Button size='sm' variant="light">
                                           <FaEdit /> تعديل
                                        </Button>
                                    </Link>

                                    <Button size='sm' variant="danger" onClick={() => handleDelete(post.id)} className="me-2">
                                        <FaTrash /> حذف
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </Container>
    );
};

export default ArticlesList;
