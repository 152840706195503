import { Container, Navbar, Nav, Button, NavDropdown, Image } from 'react-bootstrap';
import { useAuth } from "./Authentication/AuthProvider"; 
import { signOut } from "firebase/auth";
import { auth, firestore } from "./Authentication/firebase"; 
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { FaUser } from 'react-icons/fa';
import { FaMessage } from 'react-icons/fa6';

const AppNavbar = ({ darkMode, toggleDarkMode }) => {
  const [userInfo, setUserInfo] = useState(null); 
  const { currentUser } = useAuth(); 
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("/"); // Default active link is "/"

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (currentUser) {
        const userRef = doc(firestore, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setUserInfo(userSnap.data()); 
        } else {
          console.error("User info not found");
        }
      }
    };

    fetchUserInfo();
  }, [currentUser]);

  // Handle logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  // Function to set the active link and navigate
  const handleLinkClick = (link) => {
    setActiveLink(link);
    navigate(link); // Navigate to the clicked page
  };

  return (
    <Navbar className="sticky-top" variant={darkMode ? "dark" : "light"} bg={darkMode ? "dark" : "light"} expand="lg">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to="/"
              className={darkMode ? "text-white" : "text-dark"}
              style={{ fontWeight: activeLink === "/" ? "bolder" : "normal" }} 
              onClick={() => handleLinkClick("/")}
            >
              الرئيسية
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/news"
              className={darkMode ? "text-white" : "text-dark"}
              style={{ fontWeight: activeLink === "/news" ? "bolder" : "normal" }}
              onClick={() => handleLinkClick("/news")}
            >
              أخبار
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/reports"
              className={darkMode ? "text-white" : "text-dark"}
              style={{ fontWeight: activeLink === "/reports" ? "bolder" : "normal" }}
              onClick={() => handleLinkClick("/reports")}
            >
              تقارير
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/reviews"
              className={darkMode ? "text-white" : "text-dark"}
              style={{ fontWeight: activeLink === "/reviews" ? "bolder" : "normal" }}
              onClick={() => handleLinkClick("/reviews")}
            >
              مراجعات
            </Nav.Link>

            {currentUser && (
              <>
                <Nav.Link
                  as={Link}
                  to="/articles-list"
                  className={darkMode ? "text-white" : "text-dark"}
                  style={{ fontWeight: activeLink === "/articles-list" ? "bolder" : "normal" }}
                  onClick={() => handleLinkClick("/articles-list")}
                >
                  المقالات
                </Nav.Link>
{/* 
                <Nav.Link
                    as={Link}
                    to="/encyclopedia"
                    className={darkMode ? "text-white" : "text-dark"}
                    style={{ fontWeight: activeLink === "/encyclopedia" ? "bolder" : "normal" }}
                    onClick={() => handleLinkClick("/encyclopedia")}
                  >
                    الموسوعة
                  </Nav.Link> */}

                <Nav.Link
                  as={Link}
                  to="/add"
                  className={darkMode ? "text-white" : "text-dark"}
                  style={{ fontWeight: activeLink === "/add" ? "bolder" : "normal" }}
                  onClick={() => handleLinkClick("/add")}
                >
                  إضافة مقال
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/messages"
                  className={darkMode ? "text-white" : "text-dark"}
                  style={{ fontWeight: activeLink === "/messages" ? "bolder" : "normal" }}
                  onClick={() => handleLinkClick("/messages")}
                >
                  <FaMessage/>
                </Nav.Link>
              </>
            )}

            {currentUser && userInfo ? (
              <NavDropdown title={<FaUser/>} id="profile-dropdown" align="end">
                <NavDropdown.Item as={Link} to={`/profile/${userInfo.username}`}>تعديل الملف الشخصي</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/settings">الإعدادات</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>تسجيل الخروج</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                {/* Add login/signup links if needed */}
              </>
            )}
          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;