import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';

function Privacy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <h1 className="text-center">سياسة الخصوصية</h1>
                    <p className="text-center mt-3">آخر تحديث: <strong>10 نوفمبر 2024</strong></p>

                    <p>
                        في <strong className="site-title">Soumar Tech Park</strong>، نحترم خصوصيتك ونلتزم بحماية معلوماتك الشخصية. توضح سياسة الخصوصية هذه كيفية جمع بياناتك واستخدامها وحمايتها.
                    </p>

                    <h4>المعلومات التي نجمعها</h4>
                    <p>
                        قد نقوم بجمع معلومات شخصية مثل اسمك وعنوان بريدك الإلكتروني وعنوان موقعك عند ترك تعليقات أو الاشتراك في النشرة الإخبارية.
                    </p>

                    <h4>كيفية استخدام معلوماتك</h4>
                    <ul>
                        <li>الرد على الاستفسارات والتعليقات.</li>
                        <li>تحسين المدونة وتجربة المستخدم.</li>
                        <li>إرسال رسائل بريد إلكتروني دورية بخصوص التحديثات والعروض أو المنتجات/الخدمات ذات الصلة.</li>
                    </ul>

                    <h4>ملفات تعريف الارتباط (Cookies)</h4>
                    <p>
                        تستخدم مدونتنا ملفات تعريف الارتباط لتحسين تجربة المستخدم. يمكنك اختيار قبول أو رفض ملفات تعريف الارتباط. قد يؤدي الرفض إلى عدم القدرة على الاستفادة الكاملة من المدونة.
                    </p>

                    <h4>الخدمات التابعة لجهات خارجية</h4>
                    <p>
                        قد نستخدم خدمات جهات خارجية (مثل Google Analytics) لتحليل حركة المرور وسلوك المستخدم. قد تقوم هذه الخدمات بجمع بيانات بناءً على تفاعلاتك مع موقعنا.
                    </p>

                    <h4>أمان البيانات</h4>
                    <p>
                        نتخذ تدابير معقولة لحماية معلوماتك الشخصية. ومع ذلك، لا توجد طريقة لنقل البيانات عبر الإنترنت آمنة بنسبة 100٪.
                    </p>

                    <h4>التغييرات على سياسة الخصوصية هذه</h4>
                    <p>
                        قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنبلغك بالتغييرات الكبيرة في كيفية معالجة المعلومات الشخصية من خلال إرسال إشعار إلى عنوان البريد الإلكتروني الأساسي المحدد.
                    </p>

                    <h4>اتصل بنا</h4>
                    <p>
                        إذا كانت لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية هذه، يرجى الاتصال بنا على <strong><a href="mailto:soumar.tech.park@gmail.com">soumar.tech.park@gmail.com</a></strong>.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Privacy;
