// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from "firebase/database";

// Your Firebase configuration object from the Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyAxIxvIxd8yBBYI3Wsy4tFj4lvS8_heEac",
  authDomain: "soumarcv.firebaseapp.com",
  databaseURL: "https://soumarcv-default-rtdb.firebaseio.com",
  projectId: "soumarcv",
  storageBucket: "soumarcv.appspot.com",
  messagingSenderId: "779599780774",
  appId: "1:779599780774:web:58bf68e751fc911d110d67",
  measurementId: "G-RH64TQ6YX7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firebase services
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage };
export default app;
