import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer mt-3 bg-light text-dark">
      <div className="container d-flex justify-content-between align-items-center py-3">
        {/* Left section for links */}
        <div className="footer-links">
          <ul className="list-unstyled mb-0">
            <li>
              <Link to="/about" className="text-decoration-none text-dark">من نحن</Link>
            </li>
            <li>
              <Link to="/terms-of-service" className="text-decoration-none text-dark">شروط الخدمة</Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="text-decoration-none text-dark">سياسة الخصوصية</Link>
            </li>
            <li>
              <Link to="/contact" className="text-decoration-none text-dark">اتصل بنا</Link>
            </li>
            {/* <li>
              <Link to="/donate" className="text-decoration-none text-dark">Buy me a coffee <i class="bi bi-cup-hot-fill"></i></Link>
            </li> */}
          </ul>
        </div>

        {/* Right section for social media icons */}
        <div className="footer-social">
          <a href="https://www.instagram.com/soumar.techpark/" target="_blank" rel="noopener noreferrer" className="text-dark mx-2">
            <i className="bi bi-instagram fs-5"></i>
          </a>
          <a href="https://x.com/STechpark62878" target="_blank" rel="noopener noreferrer" className="text-dark mx-2">
            <i className="bi bi-twitter-x fs-5"></i>
          </a>
        </div>
      </div>

      {/* Footer bottom text */}
      <div className="py-2 footer-message">
        &copy; {currentYear} Soumar Tech Park. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;

