import React, { useEffect, useState } from 'react';
import { useParams, Link} from 'react-router-dom';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap'; // Import Spinner and Card
import { firestore } from './Authentication/firebase'; // Firebase configuration file
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
//import { formatDistanceToNow } from 'date-fns';
import { ar } from 'date-fns/locale';
import { FaRegClock } from 'react-icons/fa';
import Disqus from 'disqus-react';
import { useContext } from 'react';
import { DarkModeContext } from './DarkModeContext';
import { Helmet } from 'react-helmet';

const ArticlePage = () => {
    const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
    const { slug } = useParams(); // Get the slug from the URL parameters
    const [article, setArticle] = useState(null); // State to store the article data
    const [user, setUser] = useState(null); // State to store the user data
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // State to handle loading

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchArticle = async () => {
            try {
                const articlesRef = collection(firestore, 'articles');
                const q = query(articlesRef, where('slug', '==', slug)); // Query Firestore by slug
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docSnap = querySnapshot.docs[0];
                    const articleData = docSnap.data();
                    setArticle(articleData);

                    // Fetch user info using userId
                    const userRef = doc(firestore, 'users', articleData.userId);
                    const userSnap = await getDoc(userRef);

                    if (userSnap.exists()) {
                        setUser(userSnap.data()); // Set user data
                    } else {
                        throw new Error('User not found');
                    }
                } else {
                    throw new Error('Article not found');
                }
            } catch (err) {
                setError(err.message);
                console.error('Error fetching article:', err);
            } finally {
                setLoading(false); // Set loading to false once data is fetched
            }
        };

        fetchArticle();
    }, [slug]);

    // Show loading spinner if article is still loading
    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status" size="sm"/>
            </div>
        );
    }

    // Show error message if there was an issue
    if (error) {
        return <p className="text-danger text-center">{error}</p>;
    }

    const formatDate = (articleDate) => {
        return new Date(articleDate).toISOString().split('T')[0];
    };
    
    const disqusShortname = "soumarTechPark"
    const disqusConfig = {
        identifier: article.key,
        title: article.title,
        theme: darkMode ? "dark" : "light"
    }

    return (
        <Container className="mt-5">
             <Helmet>
                <title>{article.title}</title>
                <meta name="description" content={article.intro} />
                <meta property="og:title" content={article.title} />
                <meta property="og:description" content={article.intro} />
                <meta property="og:image" content={article.imageUrl} />
                <meta property="og:url" content={`https://www.soumar-techpark.com/articles/${slug}`} />
                <meta property="og:type" content="article" />
                <meta property="article:author" content={user.firstName + user.lastName} />
            </Helmet>

            <Row>   
                <Col md="8">
                    <h1 className="mb-2 text-bold">{article.title}</h1>
                    <p className="text-muted intro">{article.intro}</p>

                    <div className='text-center'>
                        {article.imageUrl && (
                            <Image
                            src={article.imageUrl}
                            alt="Article cover"
                            className="img-fluid rounded mb-4"
                            />
                        )}
                    </div>

                    {/* Author Section */}
                    {user && (
                      <div className="mb-2" style={{ padding: '10px' }}>
                        <Row className="align-items-center">
                                {/* Profile Image Column */}
                                {/* <Col xs={2} className="d-flex justify-content-center" style={{ paddingRight: '5px' }}>
                                    <Image
                                        src={user.profilePictureURL}
                                        alt="Profile"
                                        roundedCircle
                                        style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                    />
                                </Col> */}
                                
                                <Col>
                                    <div>
                                        {/* Name */}
                                        <p className="mb-1 text-muted" style={{ fontSize: '14px', marginBottom: '0' }}>
                                            الكاتب <Link to={`/profile/${user.username}`} className="text-decoration-none">
                                                <strong>{user.firstName} {user.lastName}</strong>
                                            </Link>
                                        </p>
                                        {/* Date */}
                                        <p className="text-muted" style={{ fontSize: '12px', marginBottom: '0' }}>
                                            <FaRegClock /> {formatDate(article.createdAt)}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}

                    <div
                        className="article-content"
                        dangerouslySetInnerHTML={{ __html: article.content }} // Render HTML content
                    />

                    {article.category === "Reviews" ? 
                    <div className='score-card'>
                        <div className="p-3 score">
                            {article.score}
                        </div>                            
                        <div className="comment mt-3"><h2><b>{article.comment}</b></h2>
                            <div className='comment-details'>
                                {article.commentDetails}
                            </div>
                        </div>
                    </div> : "" }
                    < div >
                        <Disqus.DiscussionEmbed
                            shortname={disqusShortname}
                            config={disqusConfig}
                        />
                    </div>
                </Col>
                <Col> 
                </Col>
                 <Col></Col>
            </Row>
        </Container>
    );
};

export default ArticlePage;
