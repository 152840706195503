import React, { useEffect, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { firestore } from "../Authentication/firebase";
import { collection, addDoc } from "firebase/firestore";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [status, setStatus] = useState({ success: false, error: false });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

    useEffect(()=> {
        window.scrollTo(0, 0);
    },[])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add current date to the formData object
      const dataToSubmit = {
        ...formData,
        date: new Date().toISOString(), // Add current date in ISO format
      };

      await addDoc(collection(firestore, "messages"), dataToSubmit);
      setStatus({ success: true, error: false });
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error adding message to Firestore:", error);
      setStatus({ success: false, error: true });
    }
  };

  return (
    <div className="contact-form">
      <h2>اتصل بنا</h2>
      {status.success && <Alert variant="success">تم إرسال الرسالة بنجاح!</Alert>}
      {status.error && <Alert variant="danger">فشل في إرسال الرسالة. حاول مرة أخرى لاحقاً.</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mb-3">
          <Form.Label>الاسم</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="أدخل اسمك"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>البريد الإلكتروني</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="أدخل بريدك الإلكتروني"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formMessage" className="mb-3">
          <Form.Label>الرسالة</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            rows={4}
            placeholder="أدخل رسالتك"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          إرسال
        </Button>
      </Form>
    </div>
  );
};

export default ContactUs;
