import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Spinner, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { firestore } from './Authentication/firebase';
import { collection, getDocs, query, orderBy, limit, startAfter } from 'firebase/firestore';
import { FaRegClock } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import { ar } from 'date-fns/locale';

const CategoryPage = ({ category }) => {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const batchSize = 6;

    const formatDate = (date) => {
        return formatDistanceToNow(new Date(date), { addSuffix: true, locale: ar });
    };

    const fetchArticles = async (next = false) => {
        setLoading(true);
        try {
            const articlesRef = collection(firestore, 'articles');
            let q = query(
                articlesRef,
                orderBy('createdAt', 'desc'),
                limit(batchSize * 3) 
            );

            if (next && lastVisible) {
                q = query(
                    articlesRef,
                    orderBy('createdAt', 'desc'),
                    startAfter(lastVisible),
                    limit(batchSize * 3)
                );
            }

            const querySnapshot = await getDocs(q);
            const articlesList = querySnapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                .filter(article => article.category.toLowerCase() === category.toLowerCase());

            setArticles((prev) => next ? [...prev, ...articlesList] : articlesList);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setHasMore(querySnapshot.docs.length === batchSize);
        } catch (err) {
            setError('Failed to fetch articles from Firestore: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchArticles();
    }, [category]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status" size='sm'>
                </Spinner>
            </div>
        );
    }

    return (
        <div>
            {error && <p className="text-danger text-center">{error}</p>}
            
            <Row className="mt-5">
                <Col></Col> 
                <Col md="8">
                    {articles.map((post) => (
                        <Col md={12} key={post.id}>
                            <div className="d-flex flex-column flex-md-row home-article-border-bottom">
                                <div className="p-2 text-center text-md-start">
                                    <Image 
                                        src={post.imageUrl} 
                                        rounded 
                                        className="note-image" 
                                        alt={post.title} 
                                    />
                                </div>
                                <div className="p-2">
                                    <div className="mb-1">
                                        <Link to={`/articles/${post.slug}`} className="post-title">
                                            {post.title}
                                        </Link>
                                    </div>
                                    <div className="mb-1 home-page-date">
                                        <FaRegClock /> {formatDate(post.createdAt)}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Col>
                <Col></Col>
            </Row>

            {hasMore && (
                <div className="text-center my-4">
                    <Button variant='danger' onClick={() => fetchArticles(true)} disabled={loading}>
                        {loading ? 'تحميل...' : 'تحميل المزيد'}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CategoryPage;